<template>
  <div class="fluid" id="duties">
    <b-card style="width: 80%; margin: 0 auto; margin-bottom: 20px">
      <div class="row d-flex align-items-center">
        <div class="col-md-9">
          <div class="row">
            <div class="col-md-6">
              <label for="" class="mx-1 label-font">Search:</label>
              <b-form-group>
                <b-form-input v-debounce:300="getDuties" v-model="query.keyword" type="text" placeholder="Search"
                  class="search-input bg-light" />
              </b-form-group>
            </div>
          </div>
        </div>
        <div class="col-md-3 p-0">
          <b-button v-if="$can('create', 'Duty')" v-b-modal.modal-prevent-closing
            class="btn mt-1 mt-lg-0 add-btn d-block ml-auto">
            <div class="d-flex flex-row align-items-center">
              <span class="pr-1"> Add Job Role </span>
              <span>
                <img src="@/assets/images/icons/add-icon.png" alt="add icon" height="20" width="20" />
              </span>
            </div>
          </b-button>
        </div>
      </div>
    </b-card>
    <div class="table-responsive">
      <b-table :items="duties" :fields="parentFields" show-empty responsive striped hover
        class="bg-white cursor-icon short-table" style="width: 80%; margin: 0 auto" :per-page="query.per_page">
        <template #cell(id)="row">
          <div :class="`p-1 ${row.item.status === 1 ? 'active-success' : 'active-danger'
            }`">
            <p>
              {{ row.index + 1 }}
            </p>
          </div>
        </template>
        <template #cell(name)="row">
          <p>
            {{ row.item.name }}
          </p>
        </template>
        <template #cell(action)="row">
          <div>
            <button v-if="$can('update', 'Duty')" v-b-modal.modal-prevent-closing @click.prevent="getDuty(row.item.id)"
              class="btn btn-info d-block ml-auto">
              Edit
            </button>
          </div>
        </template>
      </b-table>
    </div>

    <b-modal id="modal-prevent-closing" ref="modal" title="Create New Job Role" @show="resetModal" @hidden="resetModal"
      @ok="handleOk" :ok-title="duty.id ? 'Update' : 'Save'">
      <form ref="form">
        <b-form-group label="Name" label-for="name-input" invalid-feedback="Name is required" :state="nameState">
          <b-form-input @keyup="checkFormValidity" id="name-input" v-model="duty.name" :state="nameState" required
            class="mb-2 bg-light" placeholder="Enter Job Role name"></b-form-input>
        </b-form-group>
        <b-form-group label="Roster Color" label-for="color-input" invalid-feedback="Roster color is required">
          <div class="d-flex flex-row align-items-center row mx-1">
            <div class="col-md-3 d-flex align-items-center mb-1 px-1" v-for="(theme, index) in themes" :key="index">
              <input class="form-check-input" type="radio" :id="`color${index}`" :name="`color${index}`"
                :value="theme.color" v-model="duty.color" />
              <label :for="`color${index}`" class="color-label form-check-label">
                <div :style="{ backgroundColor: theme.color }" class="color-box"></div>
              </label>
            </div>
          </div>

        </b-form-group>
      </form>
    </b-modal>
  </div>
</template>

<script>
import {
  BButton,
  BCard,
  BCol,
  BFormGroup,
  BFormInput, BFormRadioGroup, BPagination,
  BRow,
  BTable
} from "bootstrap-vue";

import { toast } from "@/utils/toast";
import vSelect from "vue-select";

export default {
  components: {
    vSelect,
    BFormGroup,
    BCard,
    BRow,
    BCol,
    BFormInput,
    BButton,
    BTable,
    BPagination,
    BFormRadioGroup
  },

  data() {
    return {
      homeLink: false,
      duties: [],
      nameState: null,
      themes: [
        {
          color: "#E571EF"
        },
        {
          color: "#7190EF"
        },
        {
          color: "#D1B41C"
        },
        {
          color: "#18E24D"
        },
        {
          color: "#FF856A"
        },
        {
          color: "#93AEFF"
        },
        {
          color: "#2AE0AA"
        },
      ],
      duty: {
        name: "",
        color: "",
      },
      query: {
        keyword: "",
        page: 1,
        per_page: 20,
      },
      parentFields: [
        {
          key: "id",
          label: "id",
          sortable: true,
        },
        {
          key: "name",
          sortable: true,
          label: "name",
        },
        {
          key: "action",
          label: "action",
        },
      ],
    };
  },
  created() {
    this.getDuties();
  },
  methods: {
    getDuties() {
      this.$store
        .dispatch("duty/getDuties", this.query)
        .then((response) => {
          this.duties = response.data.data;
        });
    },
    async getDuty(id) {
      let response = await this.$store.dispatch("duty/getDuty", id);
      this.duty = response.data;
    },
    checkFormValidity() {
      const valid = this.$refs.form.checkValidity();
     
      this.nameState = valid;
      return valid;
    },
    resetModal() {
      this.nameState = null;
      this.duty = {
        name: "",
        color: "",
      };
    },
    handleOk(bvModalEvent) {
      // Prevent modal from closing
      bvModalEvent.preventDefault();
      // Trigger submit handler
      this.handleSubmit();
    },
    handleSubmit() {
      // Exit when the form isn't valid
      if (!this.checkFormValidity()) {
        return;
      }
      if (this.duty.id) {
        this.updateDuty();
      } else {
        if (this.duty.color == "") {
          toast(
            "Validation Error",
            "AlertTriangleIcon",
            "danger",
            "Color is required"
          );
        } else {
          this.createDuty();
        }
      }
    },
    createDuty() {
      this.$store
        .dispatch("duty/createDuty", this.duty)
        .then((response) => {
          this.$bvModal.hide("modal-prevent-closing");
          this.getDuties();
          toast(
            "Success",
            "CheckCircleIcon",
            "success",
            "Duty Create Successfully"
          );
        })
        .catch((error) => {
          if (
            error.response &&
            error.response.data &&
            error.response.data.error
          ) {
            // make error object to array
            const errors = Object.keys(error.response.data.error).map((key) => {
              return error.response.data.error[key];
            });
            toast(
              "Validation Error",
              "AlertTriangleIcon",
              "danger",
              errors.join(", ")
            );
          }
        });
    },
    updateDuty() {
      this.$store
        .dispatch("duty/updateDuty", this.duty)
        .then((response) => {
          this.$bvModal.hide("modal-prevent-closing");
          this.getDuties();
          toast(
            "Success",
            "CheckCircleIcon",
            "success",
            "Duty Update Successfully"
          );
        })
        .catch((error) => {
          if (
            error.response &&
            error.response.data &&
            error.response.data.error
          ) {
            // make error object to array
            const errors = Object.keys(error.response.data.error).map((key) => {
              return error.response.data.error[key];
            });
            toast(
              "Validation Error",
              "AlertTriangleIcon",
              "danger",
              errors.join(", ")
            );
          }
        });
    }
  },
};
</script>

<style scoped>
.active-success {
  border-left: 4px solid #50c878;
  margin-right: 5px;
}

.active-danger {
  border-left: 4px solid #ff4f5d;
  margin-right: 5px;
}

.add-btn {
  border-color: white !important;

  /* Gradient */

  background: linear-gradient(#01185e 0%, #264296 44.92%, #7190ef);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  background-clip: text;
  background-color: white;
  box-shadow: 0 3px 10px rgb(0 0 0 / 0.2);
}

th:last-child {
  text-align: end !important;
}

.color-box {
  width: 40px;
  height: 40px;
  border-radius: 50%;
  margin-right: 10px;
}
</style>
<style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";
</style>
